<template>
  <v-row justify="center">
    <v-col cols="12" xl="7" lg="9">
      <ui-dialog-card
        :title="isModel ? 'Seite bearbeiten' : 'Seite hinzufügen'"
      >
        <template v-slot:text>
          <v-form v-model="isValid" @submit.prevent="submit">
            <div class="text-overline">
              Allgemein
            </div>
            <div class="grey--text mb-2">URL: {{ page.slug }}</div>
            <v-text-field
              v-model="page.name"
              label="Titel*"
              filled
              :counter="280"
              :rules="[rules.required, rules.short_text]"
            />

            <v-textarea
              v-model="page.description"
              label="Beschreibung (Markdown zulässig)*"
              filled
              :rules="[rules.required]"
            />
            <div class="text-overline mt-4">
              Header-Bild
            </div>
            <img
              v-if="page.header_img_url"
              :src="page.header_img_url"
              alt="Header-Bild"
              style="height: 100px; max-width: 300px;"
            />
            <util-file-drop :file-prop="file" @pass="file = $event" />
          </v-form>
        </template>

        <template v-slot:actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!isValid"
            :loading="loading"
            @click="submit"
          >
            {{ isModel ? "Speichern" : "Hinzufügen" }}
            <v-icon v-if="!isModel" right dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </ui-dialog-card>
      <v-form> </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

import stringToSlug from "@/helpers/stringToSlug";

export default {
  data: () => ({
    isValid: false,
    loading: false,
    file: null
  }),
  computed: {
    page: {
      set(val) {
        this.$store.commit("page/setPage", val);
      },
      get() {
        return this.$store.getters["page/page"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    isModel() {
      return !!this.$route.params.id;
    }
  },
  watch: {
    page: {
      deep: true,
      handler(val) {
        this.page.slug = stringToSlug(val.name);
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.showPage({ id: this.$route.params.id });
    } else {
      this.page = {};
    }
  },
  methods: {
    ...mapActions({
      showPage: "page/show",
      storePage: "page/store",
      updatePage: "page/update"
    }),

    // functional
    submit() {
      this.loading = true;
      if (this.isModel) {
        this.update();
      } else {
        this.store();
      }
    },
    update() {
      this.updatePage({ id: this.$route.params.id, payload: this.page })
        .then(r => {
          this.$snotify.success("Die Seite wurde erfolgreich aktualisiert.");
          if (this.file) {
            this.storeImg(r.data.page.id);
          } else {
            this.$router.push({ name: "SettingsPages" });
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Aktualisieren der Seite ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    store() {
      this.storePage({ payload: this.page })
        .then(r => {
          this.$snotify.success("Die Seite wurde erfolgreich hinzugefügt.");
          if (this.file) {
            this.storeImg(r.data.page.id);
          } else {
            this.$router.push({ name: "SettingsPages" });
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Hinzufügen der Seite ist ein Fehler aufgetreten."
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    storeImg(id) {
      const formData = new FormData();
      formData.append("file", this.file);
      this.axios
        .post(`pages/image/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          this.$router.push({ name: "SettingsPages" });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
