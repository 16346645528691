<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="9" lg="11">
        <v-row align="center">
          <v-col cols="12" xl="4" lg="5" md="6">
            <v-text-field
              v-model="search"
              solo
              solo-inverted
              flat
              placeholder="Suchen"
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" xl="3" lg="4" md="6">
            <models-form-create-dialog />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-card>
              <settings-data-table
                :items="forms"
                :headers="headers"
                :search="search"
                edit-route="SettingsFormsEdit"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Beschreibung", value: "description" },
      { text: "Status ", value: "is_draft" },
      { value: "action", sortable: false }
    ]
  }),
  computed: {
    forms() {
      return this.$store.getters["form/forms"];
    }
  },
  created() {
    this.indexForms({});
  },
  methods: {
    ...mapActions({
      indexForms: "form/index"
    })
  }
};
</script>

<style scoped></style>
