<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {},
  watch: {},
  created() {
    this.routeForward();
  },
  methods: {
    ...mapActions({
      storeFromTemplate: "form/storeFromTemplate"
    }),

    routeForward() {
      this.storeFromTemplate({ templateId: this.$route.params.id }).then(r => {
        this.$router.push({
          name: "FormsEdit",
          params: { id: r.data.form.id }
        });
      });
    }
  }
};
</script>

<style scoped></style>
