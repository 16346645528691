<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="7" lg="9">
        <v-row>
          <v-col cols="12" xl="3" lg="4">
            <v-btn depressed block :to="{ name: 'SettingsPagesCreate' }">
              Neue Seite <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <draggable
              v-model="pages"
              group="pages"
              handle=".draggable-handle"
              @input="updateOrder({ payload: pages })"
            >
              <template v-for="(page, index) in pages">
                <organisms-pages-item
                  :key="page.id"
                  :page="page"
                  :index="index + 1"
                  class="mb-3"
                />
              </template>
            </draggable>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({}),
  computed: {
    pages: {
      get() {
        return this.$store.getters["page/pages"];
      },
      set(val) {
        this.$store.commit("page/setPages", val);
      }
    }
  },
  created() {
    this.indexPages();
  },
  methods: {
    ...mapActions({
      indexPages: "page/index",
      updateOrder: "page/updateOrder"
    })
  }
};
</script>

<style scoped></style>
