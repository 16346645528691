<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="7" lg="9">
        <div class="text-h4">Unterlagen von {{ user.name_reverse }}</div>
        <div class="mt-8">
          <div class="text-h4">
            Hochgeladenen Unterlagen
          </div>
          <v-card v-if="uploads && uploads.length > 0" class="mt-4">
            <template v-for="upload in uploads">
              <organisms-upload-item
                :key="`${upload.id}-item`"
                no-delete
                :upload-prop="upload"
              />
            </template>
          </v-card>
          <div v-else class="mt-4">
            Noch keine Unterlagen hochgeladen
          </div>
        </div>

        <div class="mt-8">
          <div class="text-h4">
            Generierte Unterlagen
          </div>
          <v-card v-if="forms && forms.length > 0" class="mt-4">
            <template v-for="form in forms">
              <v-list-item :key="`${form.id}-item`" three-line>
                <!--                <v-list-item-action>
                  <v-avatar icon color="transparent">
                    <v-icon color="primary">
                      {{
                        form.is_fulfilled
                          ? "mdi-check-circle"
                          : "mdi-checkbox-blank-circle-outline"
                      }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>-->
                <v-list-item-content>
                  <v-list-item-title>
                    {{ form.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ form.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    disabled
                    icon
                    :to="{ name: 'FormsEdit', params: { id: form.id } }"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`${form.id}-divider`" />
            </template>
          </v-card>
          <div v-else class="mt-4">
            Noch keine Unterlagen hochgeladen
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    uploads: [],
    forms: [],
    user: {},
    uploadFormKey: 0,
    uploadProgress: 0,
    isUploaded: false
  }),
  computed: {},
  watch: {},
  created() {
    this.showConfidant({ id: this.$route.params.id }).then(r => {
      this.uploads = r.data.uploads;
      this.forms = r.data.forms;
      this.user = r.data.user;
    });
  },
  methods: {
    ...mapActions({
      showConfidant: "confidant/show"
    }),
    //functional
    close() {
      //
    }
  }
};
</script>

<style scoped></style>
