<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="10" lg="12">
        <v-stepper v-if="form.id" v-model="stepperVal" alt-labels>
          <v-stepper-header>
            <template v-for="(section, index) in form.formSectionItems">
              <v-divider v-if="index !== 0" :key="`form-section-${index}`" />
              <v-stepper-step
                :key="`form-stepper-${index}`"
                :step="index + 1"
                editable
              >
                {{ section.name }}
              </v-stepper-step>
            </template>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xl="5" lg="6">
        <div class="ma-n4">
          <v-tabs-items v-model="tabs" style="background-color: transparent;">
            <template v-for="(section, index) in form.formSectionItems">
              <v-tab-item :key="`section-content-${index}`">
                <div class="pa-4 py-8 mt-1">
                  <div class="text-overline mb-2">
                    Bearbeiten
                  </div>
                  <v-card class="form-edit-v-card d-flex flex-column">
                    <v-card-text class="flex-grow-1" style="overflow: auto;">
                      <models-form-item-parent-element
                        :form-item="section"
                        :class="`${index !== 0 ? 'mt-2' : ''}`"
                      />
                    </v-card-text>
                    <v-card-text class="d-flex">
                      <v-spacer />
                      <v-btn
                        text
                        :disabled="tabs === 0"
                        class="mr-2"
                        large
                        @click="tabs--"
                      >
                        <v-icon left>
                          mdi-chevron-left
                        </v-icon>
                        Zurück
                      </v-btn>

                      <v-btn
                        color="primary"
                        :disabled="tabs === form.formSectionItems.length - 1"
                        large
                        @click="tabs++"
                      >
                        Weiter
                        <v-icon right dark>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </div>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </div>
      </v-col>
      <v-col cols="12" xl="5" lg="6" class="py-8">
        <div class="text-overline mb-2">
          Vorschau
        </div>
        <v-card class="form-edit-v-card">
          <v-card-text style="overflow: auto; height: 100%;">
            <models-form-form-preview />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    tabs: 0
  }),
  computed: {
    stepperVal: {
      set(val) {
        this.tabs = val - 1;
      },
      get() {
        return this.tabs + 1;
      }
    },
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    }
  },
  watch: {},
  created() {
    this.showForm({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      showForm: "form/show"
    })
  }
};
</script>

<style>
.form-edit-v-card {
  height: calc(100vh - 344px);
  min-height: 500px;
}
</style>
