<template>
  <div>
    <div class="header-wrapper mt-n13 mx-n16">
      <v-img
        v-if="page.header_img_url"
        :key="`${page.id}-img`"
        max-width="100%"
        max-height="400px"
        class="header"
        :src="page.header_img_url"
        alt="Header"
      />
      <v-img
        v-else
        max-width="100%"
        max-height="400px"
        class="header"
        src="@/assets/white_base_header.svg"
        alt="Header"
      />
    </div>
    <v-row justify="center" class="content-row">
      <v-col cols="12" xl="7" lg="8" md="12" class="px-4">
        <div class="text-h3 text-center" style="position: relative;">
          {{ page.name }}
        </div>
        <v-card class="mt-6">
          <v-card-text>
            <VueMarkdown
              :key="`${page.id}-md`"
              class="markdown-wrapper"
              :breaks="false"
            >
              {{ page.description }}
            </VueMarkdown>
            <div class="mt-4">
              <v-row>
                <template v-for="pageItem in page.pageItems">
                  <v-col :key="`${pageItem.id}-col`" cols="12" md="6">
                    <organisms-page-items-show-item :page-item="pageItem" />
                  </v-col>
                </template>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    page() {
      return this.$store.getters["page/page"];
    }
  },
  watch: {},
  created() {
    this.showPage({ slug: this.$route.params.slug }).then(r => {
      this.$route.meta.barTitle = r.data.page.name;
    });
  },
  methods: {
    ...mapActions({
      showPage: "page/showBySlug"
    })
  }
};
</script>

<style scoped>
.content-row {
  margin-top: -128px;
}

.markdown-wrapper > p {
  margin-bottom: 12px;
}

.markdown-wrapper > p:last-child {
  margin-bottom: 0;
}
</style>
