<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="7" lg="9">
        <div>
          <div class="text-h4">
            Neues Dokument hochladen
          </div>
          <v-card class="mt-4">
            <v-card-text>
              <v-autocomplete
                v-model="upload.uploadable_id"
                :items="documents"
                item-text="name"
                item-value="id"
                filled
                label="Dokument auswählen"
                :disabled="!!$route.query.document_id"
              />
              <v-fade-transition>
                <div v-if="upload.uploadable_id">
                  <div v-if="!isUploaded" key="notUploaded">
                    <ui-upload-form
                      :key="uploadFormKey"
                      :file-prop="file"
                      @input="file = $event"
                    />
                    <v-slide-y-transition>
                      <v-form
                        v-if="!loadingFile && file"
                        v-model="isValid"
                        class="mt-4"
                        @submit.prevent="submit"
                      >
                        <div class="mb-1 text-h6">
                          {{ file.name }}
                        </div>
                        <div class="mb-2">
                          Geben Sie der Datei eine Bezeichnung, sodass Sie
                          leichter zuzuordnen ist.
                        </div>
                        <v-text-field
                          v-model="upload.name"
                          filled
                          :rules="[rules.required, rules.short_text]"
                          label="Bezeichnung*"
                          placeholder="z. B. Organspendeausweis vorne"
                        />
                      </v-form>
                      <div v-if="loadingFile && file">
                        <v-list-item class="px-0">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ upload.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ file.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-progress-linear
                          v-model="uploadProgress"
                          color="secondary"
                          height="25"
                        >
                          {{ uploadProgress }}%
                        </v-progress-linear>
                      </div>
                    </v-slide-y-transition>
                  </div>
                  <div v-else key="uploaded">
                    <sweetalert-icon icon="success" />
                    <div class="text-h6 text-center">
                      Die Datei wurde erfolgreich hochgeladen!
                    </div>
                  </div>
                </div>
              </v-fade-transition>
            </v-card-text>
            <v-card-actions>
              <v-fade-transition>
                <div
                  v-if="!isUploaded"
                  key="notUploadedButtons"
                  style="width: 100%"
                >
                  <v-btn
                    color="primary"
                    block
                    :disabled="!isValid || !file"
                    :loading="loadingFile"
                    @click="submit"
                  >
                    Hochladen
                    <v-icon right dark>
                      mdi-upload-outline
                    </v-icon>
                  </v-btn>
                  <v-btn text block @click="close">
                    Abbrechen
                  </v-btn>
                </div>
                <div v-else key="uploadedButtons" style="width: 100%">
                  <v-btn
                    color="primary"
                    block
                    @click="
                      close();
                      reloadAll();
                    "
                  >
                    Okay
                  </v-btn>
                </div>
              </v-fade-transition>
            </v-card-actions>
          </v-card>
        </div>
        <div class="mt-8">
          <div class="text-h4">
            Meine hochgeladenen Unterlagen
          </div>
          <v-card
            v-if="uploadedDocuments && uploadedDocuments.length > 0"
            class="mt-4"
          >
            <template v-for="upload in uploadedDocuments">
              <organisms-upload-item
                :key="`${upload.id}-item`"
                :upload-prop="upload"
              />
            </template>
          </v-card>
          <div v-else class="mt-4">
            Noch keine Unterlagen hochgeladen
          </div>
        </div>

        <div class="mt-8">
          <div class="text-h4">
            Meine generierten Unterlagen
          </div>
          <v-card v-if="myForms && myForms.length > 0" class="mt-4">
            <template v-for="form in myForms">
              <v-list-item :key="`${form.id}-item`" three-line>
                <v-list-item-action>
                  <v-avatar icon color="transparent">
                    <v-icon color="primary">
                      {{
                        form.is_fulfilled
                          ? "mdi-check-circle"
                          : "mdi-checkbox-blank-circle-outline"
                      }}
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ form.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ form.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    :to="{ name: 'FormsEdit', params: { id: form.id } }"
                  >
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="`${form.id}-divider`" />
            </template>
          </v-card>
          <div v-else class="mt-4">
            Noch keine Unterlagen hochgeladen
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    isValid: false,
    loadingFile: false,
    upload: {
      uploadable_id: "",
      uploadable_type: "App\\Models\\Document",
      name: ""
    },
    file: null,
    uploadFormKey: 0,
    uploadProgress: 0,
    isUploaded: false
  }),
  computed: {
    documents() {
      return this.$store.getters["document/documents"];
    },
    uploadedDocuments() {
      return this.$store.getters["document/uploadedDocuments"];
    },
    myForms() {
      return this.$store.getters["state/userDetails"].forms;
    },
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {},
  created() {
    this.indexDocuments();
    if (this.$route.query.document_id) {
      this.upload.uploadable_id = this.$route.query.document_id;
    }
    this.indexUploadedDocuments();
  },
  methods: {
    ...mapActions({
      indexDocuments: "document/index",
      indexUploadedDocuments: "document/indexUploadedDocuments"
    }),
    //functional
    close() {
      this.loadingFile = false;
      this.file = null;
      this.uploadFormKey++;
      this.uploadProgress = 0;
      this.isUploaded = false;
      this.upload = {
        uploadable_id: "",
        uploadable_type: "App\\Models\\Document",
        name: ""
      };
      this.indexUploadedDocuments();
    },
    reloadAll() {
      this.$router.push({ name: "Documents" });
    },
    submit() {
      //
      this.storeFile();
    },
    storeFile() {
      this.loadingFile = true;
      const formData = new FormData();

      formData.append("file", this.file);
      formData.append("name", this.upload.name);

      this.axios
        .post(`upload/document/${this.upload.uploadable_id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadProgress = Math.round(
              (100 * progressEvent.loaded) / progressEvent.total
            );
          }.bind(this)
        })
        .then(() => {
          this.$snotify.success("Die Datei wurde erfolgreich hochgeladen.");
          this.isUploaded = true;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
        })
        .finally(() => {
          this.loadingFile = false;
        });
    }
  }
};
</script>

<style scoped></style>
