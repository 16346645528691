<template>
  <v-row justify="center">
    <v-col cols="12" xl="6" lg="8">
      <v-tabs
        id="form-form-header"
        v-model="tab"
        background-color="transparent"
      >
        <v-tab>Allgemein</v-tab>
        <v-tab>Elemente</v-tab>
        <v-tab>Inhalt</v-tab>
        <v-tab>Vorschau</v-tab>
      </v-tabs>
      <!-- toolbar -->
      <div class="mx-n4 my-4" style="position: sticky; top: 84px; z-index: 1;">
        <v-fade-transition>
          <v-toolbar dense class="rounded-xl">
            <v-fade-transition>
              <models-label-item v-if="form.is_draft" :label="draftLabel" />
              <models-label-item v-else :label="noDraftLabel" dark />
            </v-fade-transition>
            <v-divider vertical class="mx-3" />
            <div v-if="!saving" class="text-caption">
              <div>Zuletzt gespeichert:</div>
              <div>
                <span v-if="form.updated_at">
                  {{ form.updated_at | moment("calendar") }}
                </span>
                <span v-else>Nie</span>
              </div>
            </div>
            <v-progress-circular v-else indeterminate size="20" width="2" />
            <v-spacer />
            <v-fade-transition>
              <v-btn
                v-if="form.is_draft"
                color="primary"
                rounded
                small
                dark
                @click="updateDraft({ is_draft: false })"
              >
                Veröffentlichen
                <v-icon right>
                  mdi-share-circle
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                color="primary"
                rounded
                small
                dark
                @click="updateDraft({ is_draft: true })"
              >
                Veröffentlichung zurückziehen
                <v-icon right>
                  mdi-undo
                </v-icon>
              </v-btn>
            </v-fade-transition>
          </v-toolbar>
        </v-fade-transition>
      </div>
      <div class="ma-n4">
        <v-tabs-items v-model="tab" style="background-color: transparent;">
          <v-tab-item>
            <div class="pa-4 py-8">
              <models-form-form-general />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="pa-4 py-8">
              <models-form-form-layout />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="pa-4 py-8">
              <models-form-form-body />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="pa-4 py-8">
              <models-form-form-preview is-settings />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    tab: 0,
    results: {
      biene: "Blume",
      bestauben: "bestäubt werden"
    },
    draftLabel: {
      name: "Entwurf",
      description: "Ein Entwurf ist durch Mandanten nicht auswählbar.",
      icon: "mdi-file-outline",
      color: "grey lighten-2"
    },
    noDraftLabel: {
      name: "Veröffentlicht",
      description:
        "Ein veröffentlichtes Formular ist durch Mandanten auswählbar.",
      icon: "mdi-check-circle",
      color: "success"
    }
  }),
  computed: {
    form: {
      set(val) {
        this.$store.commit("form/setForm", val);
      },
      get() {
        return this.$store.getters["form/form"];
      }
    },
    rules() {
      return this.$store.getters["application/rules"];
    },
    saving: {
      get() {
        return this.$store.getters["form/saving"];
      }
    }
  },
  watch: {},
  created() {
    this.showForm({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      showForm: "form/show",
      updateForm: "form/update"
    }),
    replaceBody(string, first) {
      return first;
    },

    updateDraft({ is_draft }) {
      this.form.is_draft = is_draft;
      this.updateForm({
        id: this.form.id,
        payload: { form: { is_draft: is_draft } }
      }).finally(() => {
        this.saving = false;
      });
    }
  }
};
</script>

<style scoped></style>
