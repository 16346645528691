<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" xl="9" lg="11">
        <v-row align="center">
          <v-col cols="12" xl="4" lg="5" md="6">
            <v-text-field
              v-model="search"
              solo
              solo-inverted
              flat
              placeholder="Suchen"
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" xl="3" lg="4" md="6">
            <v-btn
              depressed
              block
              height="38px"
              :to="{ name: 'SettingsUsersCreate' }"
            >
              Neuer Nutzer
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-card>
              <v-tabs color="black" background-color="transparent">
                <v-tab> Aktiv Intern ({{ users_intern.length }}) </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <settings-data-table
                      :items="users_intern"
                      :headers="headers"
                      :search="search"
                      edit-route="SettingsUsersEdit"
                    />
                  </v-card>
                </v-tab-item>

                <v-tab> Aktiv Extern ({{ users_extern.length }}) </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <settings-data-table
                      :items="users_extern"
                      :headers="headers"
                      :search="search"
                      edit-route="SettingsUsersEdit"
                    />
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    users_intern: [],
    users_extern: [],
    search: "",
    headers: [
      { text: "Pers. Nr.", value: "label" },
      { text: "Nachname", value: "last_name" },
      { text: "Vorname", value: "first_name" },
      { value: "action", sortable: false }
    ]
  }),
  computed: {},
  created() {
    this.indexUsers();
  },
  methods: {
    indexUsers() {
      this.axios
        .get("/users")
        .then(res => {
          this.users_intern = res.data.users_intern;
          this.users_extern = res.data.users_extern;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
